<template>
  <div id="backup" class="screen text-align-center visibility-hidden faded-show">
    <div class="middle">
      <div class="middle-content">
        <Lottie name="paper" :style="{ width: '120px', height: '120px', position: 'relative' }" class="screen-lottie" />

        <div class="screen-title">Wallet Seed</div>

        <div class="screen-text">
          Write down these 64 symbols<br />
          and store them in secret place.
        </div>

        <div class="screen-text">
          Use these seed to restore access to<br />
          your wallet if you lose your password or<br />
          access to this device.
        </div>

        <div id="createWords" class="mt-10">
          <div class="create-word-item">
            <span @click="copy(newSeed)">{{ newSeed }}</span>
          </div>
        </div>

        <div class="clear-both">
          <button @click="router.push('/wallet')" id="backup_continueBtn" class="btn-blue screen-btn mt-26 mb-20">
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter, ref, API, onMounted, copy } from '@/logic/functions'

const router = useRouter()

const newSeed = ref('')

onMounted(async() => {
  const wallet = await API('create_wallet')
  console.log(wallet)
  newSeed.value = wallet.seed
})
</script>